<template>
  <div>
    <el-upload
      :action="picsPath()"
      list-type="picture-card"
      :multiple="imgLimit > 1 ? true : false"
      :on-preview="handlePictureCardPreview"
      :limit="imgLimit"
      :file-list="imgsPreviewList"
      :before-upload="handleBeforeUpload"
      :on-exceed="handleExceed"
      :on-success="handleSuccess"
      :on-remove="handleRemove"
      class="ps-re"
      :ref="uploadType" 
      :disabled="!toEnable"
      :show-file-list="!drag"
      :drag="isDrag"
      
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <span class="c-9" v-if="toEnable && !drag && !hideText"
      >建议图片尺寸{{ imgSize }}，图片大小不超过{{ imgVolume }}kb，最多添加{{
        imgLimit
      }}张</span
    >
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="加载失败" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    imgIndex: Number,
    // 是否显示文字内容
    hideText: Boolean,
    // 隐藏图片列表
    drag: Boolean,
    //图片回填预览
    imgsPreview: String, //传过来的照片字符串
    //图片上传限制
    imgLimit: {
      type: Number,
      default: 6,
    },
    // 是否支持拖拽
    isDrag:{
      type:Boolean,
      default:false
    },
    //图片尺寸
    imgSize: {
      type: String,
      default: "690*420",
    },
    //图片限制大小
    imgVolume: {
      type: Number,
      default: 500,
    },
    // 对应值：logo图——>logo，主图——>main；辅图——>pics
    uploadType: {
      type: String,
      default: "main",
    },
    //超出图片张数提示
    exceedInfo: {
      type: String,
      default: "辅图",
    },
    //组件传参，如遍历数组的索引
    params: {
      type: Object,
    },
    toEnable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 用于上传图片的预览功能
      dialogImageUrl: "",
      dialogVisible: false,
      logoImg: "",
      mainImg: "",
      subImgs: [],
      imgsPreviewList: [],
      imgData: {
        logoImg: "",
        mainImg: "",
        subImgs: "",
      },
      size: {
        width: 0,
        height: 0,
      },
      // meiImageList: [],
    };
  },
  watch: {
    imgsPreview(val) {
      setTimeout(() => {
        this.imgsPreviewList = this.picRefill(val);
        
        if (!val && this.uploadType != "pics") {
          this.mainImg = "";
          this.logoImg = "";
          this.imgData = {
            logoImg: "",
            mainImg: "",
            subImgs: "",
          };
        }
        this.hideAddArea();
      }, 100);
    },
  },
  mounted() {
    let imgSizeArr = this.imgSize && this.imgSize.split("*");
    this.size.width = imgSizeArr[0];
    this.size.height = imgSizeArr[1];
    this.imgsPreviewList = this.picRefill(this.imgsPreview);
    this.hideAddArea();
  },
  methods: {
    // 图片预览方法
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 图片超出限定数量
    handleExceed(file, fileList) {
      if (fileList.length == 1) {
        this.$message({
          message: `${this.exceedInfo}只可上传1张`,
          type: "warning",
        });
      } else {
        this.$message({
          message: `${this.exceedInfo}最多上传${this.imgLimit}张`,
          type: "warning",
        });
      }
    },
    //上传前校验
    handleBeforeUpload(file) {
      console.log('文件',file)
      let _this = this
      return new Promise((resolve, reject) => {
        // 小于200KB图片不做处理,png图片不做处理
        let isLt2M = ((file.size / 1024)< 200) || file.type == "image/png"
        if(isLt2M) {
            console.log("图片太小或者是Png格式，不压缩")
            resolve(file)
            return
        }
        console.log(123)
        let image = new Image(), resultBlob = '';
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            // 进行canvas压缩
            resultBlob = _this.compressUpload(image, file);
            resolve(resultBlob)
        }
        image.onerror = (err) => {
          console.log(err)
            reject(err)
        }
      })
      // const that = this;
      // let limitSize = 0.5 * 1024 * 1024;
      // if (file.size >= limitSize) {
      //   this.$message.error(`图片大小超过500kb，请重新选择合规的图片`);
      //   return false;
      // }
      // const img = new Image();
      // const URL = window.URL || window.webkitURL;
      // img.src = URL.createObjectURL(file);
      // img.onload = function() {
      //   if (this.width > that.size.width || this.height > that.size.height)
      //     that.$message.warning(
      //       `为了更好的浏览效果，建议图片尺寸${that.imgSize}`
      //     );
      // };
    },
    // 图片压缩
    compressUpload(image, file) {
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        ctx.translate(0.5, 0.5)//解决压缩后带来的模糊问题
        let initSize = image.src.length
        let { width } = image, { height } = image
        canvas. width = width
        canvas.height = height
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(image, 0, 0, width, height)
        // 进行最小压缩0.1
        let compressData = canvas.toDataURL(file.type || 'image/jpeg', 0.5)
        // 压缩后调用方法进行base64转Blob，方法写在下边
        let blobImg = this.dataURItoBlob(compressData)
        console.log(blobImg,blobImg.size)
        return blobImg
    },
    /* base64转Blob对象 */
    dataURItoBlob(data) {
        let byteString;
        if(data.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(data.split(',')[1])
        }else {
            byteString = unescape(data.split(',')[1])
        }
      let mimeString = data
          .split(',')[0]
          .split(':')[1]
          .split(';')[0];
      let ia = new Uint8Array(byteString.length)
      for( let i = 0; i < byteString.length; i += 1) {
          ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], {type: mimeString})
    },
    //成功上传
    handleSuccess(respone, file, fileList) {
        if (!this.uploadType) return;
        if (this.uploadType == "pics") this.uploadPics(respone, file, fileList);
        if (this.uploadType != "pics") this.uploadMono(respone, file, fileList);
        this.hideAddArea();
        this.handleTransfer();
      
    },
    // 上传主(LOGO)图-成功回调
    uploadMono(response, file, fileList) {
      if (this.uploadType == "main") {
        this.imgData.mainImg = response.body;
      } else {
        this.imgData.logoImg = response.body;
      }
    },
    // 上传辅图-成功回调
    uploadPics(response, file, fileList) {
      this.subImgs = [];
      this.imgData.subImgs = "";
      fileList.forEach((item) => {
        if (item.response) {
          this.subImgs.push(item.response.body);
        } else {
          let split_url = item.url.split("/");
          let path = split_url[split_url.length - 1];
          this.subImgs.push(path);
        }
      });
      // console.log("成功上传",this.subImgs)
      this.imgData.subImgs = this.subImgs && this.subImgs.join(",");
    },
    //移除图片
    handleRemove(file, fileList) {
        if (!this.uploadType) return;
        if (this.uploadType == "pics")
          this.handleRemovePics( file, fileList);
        if (this.uploadType != "pics")
          this.handleRemoveMono(file, fileList);
        this.hideAddArea();
        this.handleTransfer();
      
    },
    //移除主(LOGO)图片
    handleRemoveMono( file, fileList) {
      this.imgData.logoImg = "";
      if (this.uploadType == "main") {
        this.mainImg = "";
        this.imgData.mainImg = "";
      } else {
        this.logoImg = "";
        this.imgData.logoImg = "";
      }
    },
    //移除辅图
    handleRemovePics(file, fileList) {
      this.removePic(this.subImgs, file);
    },
    //移除单个图片
    removePic(images, response) {
      const movePath = (imgsArr, template) => {
        let idx = imgsArr.findIndex((item) => item == template);
        if (idx != -1) imgsArr.splice(idx, 1);
      };
      if (response.response) {
        movePath(images, response.response.body);
      } else {
        let split_url = response.url.split("/");
        let path = split_url[split_url.length - 1];
        movePath(images, path);
      }
      this.imgData.subImgs = this.subImgs && this.subImgs.join(",");
    },
    //图片回填
    picRefill(paths) {
      if (!paths) return [];
      let pics_arr = [];
      if (paths.indexOf(",") == -1) {
        if (this.uploadType == "pics") this.subImgs = [paths];
        else if (this.uploadType == "main") this.mainImg = paths;
        else this.logoImg = paths;
        pics_arr.push({
          name: "foto",
          url: this.picsPath(paths),
        });
        return pics_arr;
      }
      if (paths.indexOf(",") != -1) {
        let paths_arr = paths.split(",");
        if (paths_arr) {
          this.subImgs = paths_arr;
          paths_arr.forEach((item, index) => {
            pics_arr.push({
              name: index,
              url: this.picsPath(item),
            });
          });
        }
        return pics_arr;
      }
    },
    //隐藏与显示上传操作区
    hideAddArea() {
      if (this.uploadType == "pics") {
        let imgs = this.imgData.subImgs && this.imgData.subImgs.split(",");
        if (imgs.length == 6)
          this.$refs[this.uploadType].$el.lastElementChild.style.display =
            "none";
        else
          this.$refs[this.uploadType].$el.lastElementChild.style.display =
            "inline-block";
      } else {
        if (
          this.imgData.logoImg ||
          this.imgData.mainImg ||
          this.logoImg ||
          this.mainImg
        )
          this.$refs[this.uploadType].$el.lastElementChild.style.display =
            "none";
        else
          this.$refs[this.uploadType].$el.lastElementChild.style.display =
            "inline-block";
      }
    },
    handleChange(file, fileList) {
      //美加的
      // // console.log(fileList);
      //  // console.log(this.imgsPreview); //传过来的  回填的时候
      // if ((this.name = "banner")) {
      //   let newArr = [];
      //   if (this.imgsPreview) {
      //     newArr = this.imgsPreview.split(",");
      //   }
      //   fileList.forEach((ele) => {
      //     // console.log(ele);
      //     // if (newArr.indexOf(ele.response.body) == -1) {
      //     //   newArr.push(ele.response.body);
      //     // }
      //   });
      //   this.$emit("getImgData", newArr);
      //   // console.log(newArr)
      // }
    },
    //将图片地址返回给父组件
    handleTransfer() {
      console.log(this.imgData)
      this.$emit(
        "getImgData",
        this.imgData,
        this.uploadType,
        this.params,
        this.imgIndex
      );
    },
  },
};
</script>
