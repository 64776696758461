import request from '@/utils/request'

export function queryGoodsType (params) {
    return request({
        url: '/shd-operation/lrGoodsClassify/queryGoodsType',
        method: 'get',
        params
    })
}
 // 删除分类
 export function delGoodsFreshType (id) {
    return request({
        url: `/shd-operation/lrGoodsClassify/delGoodsFreshType/${id}`,
        method: 'delete'
    })
}

///shd-operation/lrGoodsClassify/saveGoodsType
export function saveGoodsType (data) {
    return request({
        url: '/shd-operation/lrGoodsClassify/saveGoodsType',
        method: 'post',
        data
    })
}