import request from '@/utils/request'

export function saveCanvas (data) {
    return request({
        url: '/shd-operation/canvas/saveCanvas',
        method: 'post',
        data,
        
    })
}

export function queryCanvas (params) {
    return request({
        url: '/shd-operation/canvas/queryCanvas',
        method: 'get',
        params,
        
    })
}