<template>
  <div>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <el-button type="primary" class="mb-10" @click="saveCanvas()">保存</el-button>
          <el-tooltip class="item" effect="dark" :content="setModelContent" placement="top">
            <el-button type="primary" class="mb-10" @click="setModel()">设置为模板</el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="copyModelContent" placement="top">
            <el-button type="primary" class="mb-10" @click="copyModel()">复制模板</el-button>
          </el-tooltip>
          <!-----店铺导航----->
          <el-tabs
            v-model="activePart"
            @tab-click="changeTab"
            v-if="!storeFlag"
          >
            <el-tab-pane
              :label="item.storeName"
              :name="item.id + ''"
              v-for="(item, index) in shopList"
              :key="index"
            ></el-tab-pane>
          </el-tabs>
          <!-- 广告编辑列表-->
          <!-- <div class="menu"  >
                      <li  v-if="menuData" > 
                        <dd  v-for="(item, index) in menuData" :key="index"  >
                          {{getTypeName(item.type)}}：{{item.title}} 
                          <i class="el-icon-edit-outline"  @click="openCanvasItem(index)"></i>
                          <i class="el-icon-delete"  @click="deleteCanvasItem(index)"></i>
                        </dd>
                        <dd  @click="openCanvasItem(-1)">+</dd>
                      </li>
                    </div> -->
          <el-table
            ref="dragTable"
            class="table-style"
            :data="menuData"
            fit
            highlight-current-row
            border
            max-height="810px"
            default-expand-all
            row-key="index"
            :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
          >
            <el-table-column prop="type" label="组件名" header-align="center">
              <template slot-scope="scope">
                {{getTypeName(scope.row.type)}}{{scope.row.title}}
              </template>
            </el-table-column>
            <el-table-column label="编辑" width="100px" header-align="center">
              <template slot-scope="scope">
                <el-button @click="openCanvasItem(scope.row)" type="text" class="tableButton">
                  <i class="el-icon-edit-outline"></i>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="删除" width="100px" header-align="center">
              <template slot-scope="scope">
                <el-button @click="deleteCanvasItem(scope.row.index)" type="text" class="tableButton">
                  <i class="el-icon-delete" style="color:#F56C6C"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div @click="openCanvasItem(-1)" class="newAD">+</div>
        </div>
      </el-col>
    </el-row>

    <!-- 修改子菜单 -->
    <el-dialog
      :title="dialogMenuItemTitle + '子菜单'"
      :visible.sync="dialogMenuItemVisible"
      width="700px"
      center
    >
      <el-form
        :model="dialogMenuItemData"
        :rules="rules"
        ref="dialogMenuItemData"
        label-width="100px"
        class="formDialog"
      >
        <el-form-item label="组件" prop="type">
          <el-select
            clearable
            class="fleft"
            v-model="dialogMenuItemData.type"
            placeholder=""
            @change="adNameChange()"
          >
            <el-option
              :label="item.name"
              :value="item.no"
              v-for="(item, index) in typeData"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--广告图片：单图、双图、三图并列、三图左一右二、轮播图、文字分割线模块  -->
        <div class="imgBigBox" v-if="!isClassify">
          <div
            class="dialogImgBox"
            v-for="(itemPic, index) in dialogMenuItemData.componentContent.list"
            :key="index">
            <el-form-item v-if="isCutLine" style="width:80%" label="分割文字：">
              <el-input v-model="itemPic.image" class="cutLineInput" clearable></el-input>
            </el-form-item>
            <el-form-item :label="'图片：'" class="formItem1" v-if="!isCutLine">
              <UploadPics
                :uploadType="'main'"
                :imgLimit="1"
                :imgsPreview="itemPic.image"
                @getImgData="(x, y, z) => getImgData(x, y, z, index)"
                :key="index"
              />
            </el-form-item>
            <el-form-item label="类型:" class="formItem2" v-if="!isCutLine">
              <el-select
                v-model="itemPic.path_type"
                placeholder="请选择类型"
                @change="activityTypeChange()"
              >
                <el-option
                  :label="item.name"
                  :value="item.no"
                  v-for="(item, index) in activityTypeList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址:" class="formItem1" v-if="!isCutLine">
              <el-select
                v-model="itemPic.url"
                placeholder="请选择活动"
                v-if="isActivity[index]"
              >
                <el-option
                  :label="item.activityName"
                  :value="item.id"
                  v-for="(item, index) in activityURLList"
                  :key="index"
                ></el-option>
              </el-select>
              <el-input v-model="itemPic.url" v-if="!isActivity[index]" clearable></el-input>
            </el-form-item>
          </div>
          <div
            class="addpictureBox"
            type="primary"
            @click="addpictureBox('dialogMenuItemData')"
            v-if="isBannerAD"
          >
            +
          </div>
        </div>
        <!-- 商品分类模块 -->
        <div class="imgBigBox" v-if="isClassify">
          <div class="dialogImgBox" v-for="(itemPic, index) in dialogMenuItemData.componentContent.list" :key="index">
            <el-form-item :label="'图片'+Number(index+1)+':'" class="formItem1" v-if="!isCutLine">
              <UploadPics
                :uploadType="'main'"
                :imgLimit="1"
                :imgsPreview="itemPic.image"
                @getImgData="(x, y, z) => getImgData(x, y, z, index)"
                :key="index"
              />
            </el-form-item>
            <div class="formItem3">
              <el-form-item label="类型:" class="formItem3_item">
               <el-select
                v-model="itemPic.path_type"
                placeholder="请选择类型"
                @change="activityTypeChange()"
               >
                <el-option
                  :label="item.name"
                  :value="item.no"
                  v-for="(item, index) in activityTypeList"
                  :key="index"
                ></el-option>
               </el-select>
             </el-form-item>
             <el-form-item label="商品分类名:" class="formItem3_item">
               <el-select
                v-model="itemPic.classifyName"
                placeholder="请选择商品分类"
                v-if="itemPic.path_type==='classifyList'"
               >
                <el-option
                  :label="item.classifyName"
                  :value="item.classifyName"
                  v-for="(item, index) in goodsTypeList"
                  :key="index"
                ></el-option>
               </el-select>
               <el-input v-model="itemPic.classifyName" v-if="itemPic.path_type != 'classifyList'" clearable></el-input>
              </el-form-item>
             <el-form-item label="地址:" class="formItem3_item">
              <el-select
                v-model="itemPic.url"
                placeholder="请选择活动"
                v-if="isActivity[index]"
              >
                <el-option
                  :label="item.activityName"
                  :value="item.id"
                  v-for="(item, index) in activityURLList"
                  :key="index"
                ></el-option>
              </el-select>
              <el-input v-model="itemPic.url" v-if="!isActivity[index]" clearable></el-input>
             </el-form-item>
            </div>
          </div>
          <div class="addpictureBox1 addpicture" type="primary" @click="addpictureBox('dialogMenuItemData')"><i class="el-icon-plus"></i></div>
          <div class="addpictureBox1 deletepicture" type="primary" @click="deletepictureBox('dialogMenuItemData')"><i class="el-icon-minus"></i></div>
        </div>
        <div class="midBox">
          <el-button
            class="button"
            type="primary"
            @click="setCanvasItem('dialogMenuItemData')"
            >确认</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script> 
    import UploadPics from "@/components/UploadPics";
    import { getStoreList } from "@/api/common";    
    import {saveCanvas,queryCanvas} from "@/api/market/canvas";
    import { getActivityList } from "@/api/market/activity";
    import {queryGoodsType} from "@/api/market/classify_list"; 
    import Sortable from 'sortablejs';

    export default {
        name: "section_list",
        components: { 
          UploadPics,
        },
        data() {
            return { 
                menuData: [],
                dialogMenuVisible: false,
                dialogMenuData: {
                  index: '',
                  input: ''
                },
                dialogMenuItemVisible: false,
                dialogMenuItemTitle: '添加',
                dialogMenuItemData: {
                  title:'',
                  type:'',
                  componentContent:{
                    list:[
                      {image:'',url:'',path_type:'',classifyName:''},
                    ],
                    index:-1
                  }
                },
                copyDialogMenuItemData:{
                  title:'',
                  type:'',
                  componentContent:{
                    list:[
                      {image:'',url:'',path_type:'',classifyName:''},
                    ],
                    index:-1
                  }
                },
                copyDialogMenuItemDataList:[{image:'',url:'',path_type:'',classifyName:''}],
                dialogImageBoxData:{image:'',url:'',path_type:'',classifyName:''},
                typeData: [
                 // { no: 'banner', name: '轮播图' },
                  //{ no: 'noticeBar', name: '滚动新闻' },
                 // { no: 'menu', name: '菜单' },
                  { no: 'adv_1_2', name: '三图组件(左一右二)' },//三张图片
                  { no: 'adv_1', name: '单图组件' },//一张
                  { no: 'adv_2', name: '双图组件' },//两张
                  { no: 'adv_3', name: '三图组件' },//三张
                  { no:'banner',name:'Banner组件'},//轮播图
                  { no:'division',name:'分割线'},//分割线
                  { no:'classify',name:'商品分类组件'}
                ] ,
                activityTypeList:[
                  {no:'ACTIVITY_ID',name:'活动'},
                  {no:'MP_URL',name:'URL'},
                  {no:'classifyList',name:'商品分类'}
                ],
                copyActivityTypeList1:[
                  {no:'ACTIVITY_ID',name:'活动'},
                  {no:'MP_URL',name:'URL'},
                ],
                copyActivityTypeList2:[
                  {no:'ACTIVITY_ID',name:'活动'},
                  {no:'MP_URL',name:'URL'},
                  {no:'classifyList',name:'商品分类'}
                ],
                activityURLList:[],
                shopList: [], 
                storeName: "",
                storeFlag: false,
                activePart: "1",
                isBannerAD:false,
                // 临时变量区
                activityType:'',
                activityURL:'',
                isActivity:[false],
                pictureNumber:0,//图片数目控制变量
                isCutLine:false,//是否是分割线组件
                isClassify:false,//是否商品分类组件
                // 临时变量区
                // 表单必填校验
                rules:{
                  type:[{ required: true, message: '选择组件类型', trigger: 'blur' }],
                },
                goodsTypeList:[],
                copyModelContent:"点击复制配置模板到当前页",
                setModelContent:'将当前店铺配置信息设置为模板，刷新或首次进入页面需要重新设置新的模板！',
                copyModelDate:[]
            };
        },
        mounted() {
            if (this.$store.getters.storeId) {
                this.activePart = this.$store.getters.storeId;
                this.storeFlag = true;
            }
            this.getStoreList(); //查门店列表
            this.getActivityList();//查活动
            this.getGoodsTypeList();//查商品分类列表
        },
        watch: {
            activePart(val) {
                // this.search();
            }
        },
        methods: {
           // 获取活动列表
          getActivityList() {
            return new Promise((resolve, reject) => {
              let params = {
                storeId: this.activePart,
                pageNum: 1,
                pageSize: 9999,
                section: 1
              };
              getActivityList(params)
                .then((res) => {
                  let data = res.data.body;
                  this.activities = data.list.filter((item) => item.status == 1);
                  this.activityURLList=this.activities
                  console.log(this.activities,this.activityURLList)
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          },
           // 获取类型
          getTypeName(type) {
              let temp = "";
             
              this.typeData.forEach(e => {
                if (e.no == type) {
                  temp = e.name;
                }
              });
               
              return temp;
          },
          // 获取广告信息
          getCanvasData () { 
            let params = {
                tabBarCode:"SHANGCHAO_HOME",
                storeId: Number(this.activePart),
            };
            queryCanvas(params).then(data => {  
              if(data.data.body!=null){
                this.menuData = data.data.body.canvasJson? JSON.parse(data.data.body.canvasJson):[]
              }else{
                  this.menuData =[];
              }
            })
            this.$nextTick(()=>{
              this.setSort()
            })
          },
          // 组件弹窗确认暂存到主编辑页
          setCanvasItem (formName) {
            let warmmingFlag=0
            this.$refs[formName].validate((valid) => {
                // 自带表单校验组件名称选择
                if (valid) {
                  if(this.isClassify){
                    for(let i=0;i<this.dialogMenuItemData.componentContent.list.length;i++){
                    if(this.dialogMenuItemData.componentContent.list[i].image===''|| this.dialogMenuItemData.componentContent.list[i].classifyName ===''|| this.dialogMenuItemData.componentContent.list[i].url ===''|| this.dialogMenuItemData.componentContent.list[i].path_type ===''){
                      ++warmmingFlag
                    }
                  }
                  }else if(this.isCutLine){
                    if(this.dialogMenuItemData.componentContent.list[0].image===''){
                      ++warmmingFlage
                    }
                  }else{
                    for(let i=0;i<this.dialogMenuItemData.componentContent.list.length;i++){
                    if(this.dialogMenuItemData.componentContent.list[i].image===''|| this.dialogMenuItemData.componentContent.list[i].url ===''||this.dialogMenuItemData.componentContent.list[i].path_type===''){
                      ++warmmingFlag
                    }
                  }
                  }
                  // 图片信息校验
                  if(warmmingFlag>0){
                    this.$message({
                          message: '警告信息未填写完整',
                          type: 'warning'
                      });
                  }else{
                    if(this.dialogMenuItemData.index===-1){  
                      this.dialogMenuItemData.index=this.menuData.length
                      this.menuData.push(JSON.parse(JSON.stringify(this.dialogMenuItemData)));
                      console.log(this.menuData,this.dialogMenuItemData)
                    }
                    this.dialogMenuItemVisible = false
                  }
                } else {
                  console.log('error submit!!');
                  return false;
                }
              });              
          }, 
        //  新增或编辑广告
          openCanvasItem (n) {
            this.isBannerAD=false
            this.isCutLine=false
            this.isClassify=false
            this.activityTypeList=JSON.parse(JSON.stringify(this.copyActivityTypeList1)) 
            if (n !== -1) {
              this.dialogMenuItemData = n
              this.dialogMenuItemData.index = n.index
              this.pictureNumber=this.dialogMenuItemData.componentContent.list.length-1
              if(this.dialogMenuItemData.type==='division'){
                this.isCutLine=true
              }else if(this.dialogMenuItemData.type==='classify'){
                this.isClassify=true
                this.activityTypeList=JSON.parse(JSON.stringify(this.copyActivityTypeList2))
              }else if(this.dialogMenuItemData.type==='banner'){
                this.isBannerAD=true
              }
              this.renderImageList()
              // this.dialogMenuItemData.title = this.menuData[n].title
              this.dialogMenuItemTitle = '修改'
              this.dialogMenuItemVisible = true
            } else {
              this.dialogMenuItemData=JSON.parse(JSON.stringify(this.copyDialogMenuItemData))
              console.log(this.dialogMenuItemData)
              this.pictureNumber=0
              this.dialogMenuItemTitle = '添加'
              this.dialogMenuItemData.index = -1
              this.dialogMenuItemVisible = true
            }
          },

          //获取门店列表
          getStoreList() {
              getStoreList()
                  .then(res => {
                      console.log("门店列表 ",res.data.body.list)
                      this.shopList = res.data.body.list; 
                      this.getCanvasData();
                  })
                  .catch(err => {
                      
                  });
          },
          // 切换tab
          changeTab() { 
            this.getCanvasData();
            console.log(this.activePart)
            this.getActivityList()
          },
          //子菜单组件广告类型变更UI交互
          adNameChange(){
            this.dialogMenuItemData.componentContent.list=JSON.parse(JSON.stringify(this.copyDialogMenuItemDataList))
            // 分割线显示隐藏判断
            console.log(this.dialogMenuItemData)
            if(this.dialogMenuItemData.type==='division'){
              this.isCutLine= true
            }else{
              this.isCutLine= false
            }
            // 图片广告判断，UI组数量控制
            if(this.dialogMenuItemData.type==='adv_1_2' || this.dialogMenuItemData.type==='adv_3'){
              // 
              this.addpictureBox()
              this.addpictureBox()
            }else if(this.dialogMenuItemData.type==='adv_2'){//两张图片广告组件
              // this.dialogMenuItemData.componentContent.list.push(JSON.parse(JSON.stringify(this.dialogImageBoxData)))
              this.addpictureBox()
            }else{
              this.pictureNumber=0
            }
            // 轮播图判断，无限新增按钮显示隐藏
            if(this.dialogMenuItemData.type==='banner'){
              this.isBannerAD=true
            }else{
              this.isBannerAD=false
            }
            // 商品分类判断，模块切换
            if(this.dialogMenuItemData.type==='classify'){
              this.activityTypeList=JSON.parse(JSON.stringify(this.copyActivityTypeList2))
              this.isClassify=true
            }else{
              this.isClassify=false
            }
          },
          // 活动类型变更UI交互
          activityTypeChange(){
            this.renderImageList()
          },
          // 循环控制
          renderImageList(){
            for(let i= 0 ;i<this.dialogMenuItemData.componentContent.list.length;i++){
              if(this.dialogMenuItemData.componentContent.list[i].path_type==='ACTIVITY_ID'){
                this.isActivity[i]=true
              }else{
                this.isActivity[i]=false
              }
            }
          },
          // 获取图片数据
          getImgData(imgData, type, params, imgIndex) {
            if (type == "main") {
            console.log(imgData,params,imgIndex)
            this.dialogMenuItemData.componentContent.list[imgIndex].image = imgData.mainImg;
            console.log(this.dialogMenuItemData)
          }
          },
          // 删除广告信息
          deleteCanvasItem(index){
            this.menuData.splice(index,1)
            for(let i=0;i<this.menuData.length;i++){
              this.menuData[i].index=i
            }
          },
          // 提交保存
          saveCanvas(){
            return new Promise((resolve, reject) => {
            let params = {
              storeId:this.activePart,
              tabBarCode:'SHANGCHAO_HOME',
              canvasJson:JSON.stringify(this.menuData)   
            };
            saveCanvas(params)
              .then((res) => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          });
          },
          // banner图组件点击下方新增增加图片导入组
          addpictureBox(){
            this.isActivity.push(false)
            this.dialogMenuItemData.componentContent.list.push(JSON.parse(JSON.stringify(this.dialogImageBoxData)))
          },
          deletepictureBox(){
            this.isActivity.pop()
            this.dialogMenuItemData.componentContent.list.pop()
          },
          rowDrop(){
            const tbody = document.querySelector('.el-table__body-wrapper tbody')
            console.log(tbody)
            const _this = this
            Sortable.create(tbody, {
              onEnd({ newIndex, oldIndex }) {
                const currRow = _this.menuData.splice(oldIndex, 1)[0]
                _this.menuData.splice(newIndex, 0, currRow)
              }
            })
            console.log(this.menuData)
          },
          setSort() {
          // 获取元素
            const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody ')[0]
            console.log(el)
            this.sortable = Sortable.create(el, {
              ghostClass: 'sortable-ghost', // Class name for the drop placeholder,
            //  setData: function(dataTransfer) {
            //    // to avoid Firefox bug
            //    // Detail see : https://github.com/RubaXa/Sortable/issues/1012
            //    dataTransfer.setData('Text', '')
            //  },
              onEnd: evt => {
                console.log(evt.newIndex,evt.oldIndex)
                // 获取拖拽的行数据
                const targetRow = this.menuData.splice(evt.oldIndex, 1)[0]
                console.log(targetRow)
                // 设置拖拽后的行数据位置
                this.menuData.splice(evt.newIndex, 0, targetRow)
              //  强行赋值触发视图更新
                console.log(this.menuData)
                var tempArr=[]
                tempArr=JSON.parse(JSON.stringify(this.menuData))
                this.menuData=[]
                this.menuData=JSON.parse(JSON.stringify(tempArr))
              // for(let i=0;i<this.menuData.length;i++){
              //   this.menuData[i].index=i
              // }
              }
            })
          },
          getGoodsTypeList() {
              queryGoodsType().then((res) => {
              this.goodsTypeList = res.data.body;
              });
          },
          // 设置为活动模板
          setModel(){
            console.log(this.shopList,this.activePart)
            this.copyModelDate = JSON.parse(JSON.stringify(this.menuData))
            let storeName = this.shopList.filter(item=>{
              console.log(item)
              if(item.id == Number(this.activePart)){
                console.log(item.storeName)
                return item.storeName
              }
            })
            console.log(storeName)
            this.setModelContent = '当前已经存在模板，点击将会重新设置模板配置！'
            this.copyModelContent = '点击复制配置模板到当前页，当前模板店铺：'+storeName[0].storeName
          },
          // 拷贝使用活动模板
          copyModel(){
            this.menuData = JSON.parse(JSON.stringify(this.copyModelDate))
          },
         
        }
    }    
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.el-avatar /deep/ img {
  width: 100%;
}
.menu {
  width: 400px;
  height: 400px;
  margin: 0 auto;
  border: 2px solid #eee;
  li {
    float: center;
    width: 380px;
    list-style: none;
    text-align: center;
    h1 {
      font-size: 16px;
      font-weight: 400;
      line-height: 40px;
      background: #eee;
    }
    dd {
      font-weight: 15px;
      line-height: 40px;
      background: #eee;
      margin: 0 10% 10px;
      i {
        padding: 6px;
        border-radius: 50%;
        background: #ffffff;
        border: 1px solid #ccc;
      }
    }
  }
}
.dialogImgBox {
  width: 98%;
  height: 175px;
  margin-bottom: 22px;
  border-bottom: 1px dashed #409EFF;
  .formItem1 {
    width: 34%;
    float: left;
  }
  .formItem2 {
    width: 30%;
    float: left;
  }
  .formItem3 {
    width: 55%;
    float: left;
    margin-left: 40px;
    .formItem3_item{
      margin-bottom: 12px;
      .el-select{
        width: 100%;
      }
    }
  }
}
.el-upload {
  width: 100%;
}
/deep/.el-upload-dragger {
  width: 100%;
  height: 35px;
}
/deep/.c-9 {
  display: none;
}
.imgBigBox {
  width: 675px;
  height: 400px;
  overflow: auto;
  padding: 0;
  margin: 0;
}
.addpictureBox {
  width: 98%;
  text-align: center;
  color: c0ccda;
  border: 1px dashed #c0ccda;
  margin-bottom: 12px;
  height: 36px;
  line-height: 34px;
}
.addpictureBox1 {
  width: 45%;
  text-align: center;
  color: c0ccda;
  margin-bottom: 12px;
  height: 36px;
  line-height: 34px;
  margin-left: 12px;
  margin-right: 12px;
  float: left;
}
.addpicture{
  border: 1px dashed #409EFF;
  .el-icon-plus{
    color:#409EFF
  }
}
.deletepicture{
  border: 1px dashed #F56C6C;
  .el-icon-minus{
    color: #F56C6C;
  }
}
.midBox {
  display: flex;
  justify-content: center;
  width: 100%;
}
.sortable-ghost{
  opacity: .8;
  color: #fff !important;
  background: #42b983 !important;
}
.el-table{
  width: 500px;
  margin: 0 auto;
}
.tableButton{
  width: 100%;
  display: flex;
  justify-content: center;
}
.newAD{
  width: 500px;
  margin: 12px auto;
  text-align: center;
  border: 1px dashed #c0ccda;
  height: 50px;
  font-size: 36px;
  line-height: 50px;
  color: #409EFF
}
</style>
